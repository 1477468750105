import React, { useEffect, useState } from "react";
import { useUser } from "@/contexts/userContext";
import { Box } from "@mui/material";
import { Loader, LoadingView } from "@/components/atoms";
import LoginForm from "@/components/organisms/LoginForm";
import { useRouter } from "next/router";

export default function LoginPage() {
  const router = useRouter();
  const params = router.query;
  const { state, dispatch } = useUser();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hostname = window.location.hostname;
    const re = /(?<orgId>[a-z0-9-]+)\.account\.glidenow\.(com|rocks)/;
    const matches = re.exec(hostname);
    if (matches?.groups?.orgId && !state.orgId) {
      dispatch({ type: "setOrgId", orgId: matches?.groups?.orgId });
    }

    if (state.orgId && !params.token) {
      setShowLoginForm(true);
    }
    setIsLoading(false);
  }, [state, params?.token]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (showLoginForm) {
    return <LoginForm />;
  }

  return (
    <p>
      It looks like you&apos;re trying to login but you&apos;ve reached the
      wrong URL. Try clicking the link in your email again or contact support.
    </p>
  );
}
