import { Alert, Button, Stack, TextField, Grid } from "@mui/material";
import { Form, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useUser } from "@/contexts/userContext";
import { corkscrewApiFactory } from "@/api/corkcrew";
import Bugsnag from "@bugsnag/js";
import { useRouter } from "next/router";
import routes from "@/utils/routes";
import { useState } from "react";

const schema = yup
  .object({
    email: yup.string().email().required(),
    orderNumber: yup.string().required(),
  })
  .required();

export default function LoginForm() {
  const router = useRouter();
  const { state, dispatch } = useUser();
  const { register, handleSubmit, watch, formState, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      orderNumber: "",
    },
  });
  const [loginFail, setLoginFail] = useState(false);

  const onHandleSubmit = async (data: {
    email: string;
    orderNumber: string;
  }) => {
    setLoginFail(false);

    try {
      const resp = await fetch("/api/v1/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "x-org-id": state.orgId,
        },
      });
      const respData = await resp.json();

      dispatch({ type: "setBrand", brand: respData.brand });

      const csApi = corkscrewApiFactory(
        respData.apiEndpoint,
        state.orgId,
        respData.loginToken || ""
      );

      const profile = await csApi.getProfile();
      dispatch({
        type: "setLoggedIn",
        loggedIn: true,
        userId: profile.customerId,
        shopifyOrderUrl: "",
        token: respData.loginToken || "",
        cartUrl: "",
        canDownloadInvoice: false,
        canReorder: false,
        orgId: state.orgId,
        apiEndpoint: respData.apiEndpoint,
      });

      Bugsnag.setUser(profile.customerNumber, profile.email, profile.email);
      dispatch({ type: "setProfile", profile });

      router.replace(routes.orders);
    } catch (err) {
      Bugsnag.notify(err);
      setLoginFail(true);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <Form
          control={control}
          onSubmit={() => {
            handleSubmit(onHandleSubmit)();
          }}
        >
          <Stack gap={2}>
            <h1>Login</h1>
            <TextField
              label="Email Address"
              {...register("email")}
              error={Boolean(formState.errors?.email)}
              helperText={formState?.errors?.email?.message}
              placeholder="hello@thebestwebsite.com"
            />
            <TextField
              label="Order Number"
              helperText="Looks something like #15121 or SA12351. It'll be on your order confirmation"
              {...register("orderNumber")}
              error={Boolean(formState.errors?.orderNumber)}
              placeholder="SA12351"
            />
            <Button
              variant="contained"
              disabled={
                !formState.isValid ||
                !formState.isDirty ||
                formState.isSubmitting
              }
              type="submit"
            >
              Login
            </Button>
            {loginFail ? (
              <Alert variant="filled" severity="warning">
                Your details were not recognised
              </Alert>
            ) : null}
          </Stack>
        </Form>
      </Grid>
    </Grid>
  );
}
